@media (width > 800px) {
    .nav_logo-container {
        text-decoration: none;
        color: #0E73B7;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .nav_logo-container p {
        font-size: 33px;
        font-weight: 400;
        font-family: sans-serif;
    }

    .nav_logo {
        width: 43px;
        position: relative;
        margin-right: -10px;
        margin-left: -10px;

    }

    .navigation-container {
        position: absolute;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }


    .nav_header {
        display: flex;
        align-items: center;
        gap: 20px;
        margin: 10px 10vw;

    }

    .nav_name {
        font-family: montserrat-regular;
        font-size: 30px;
        position: relative;
        top: 2px;
        color: #2A7CB4;
    }

    .nav_logo {
        width: 45px;
    }

    .nav_arr-ico,
     .disnone-pc,
     .nav_mobile-ico_btn{
        display: none;
    }

    .nav_links-pc{
        display: flex;
        margin: 10px 10vw;
        gap: 25px;
        align-items: center;
    }



    .nav_link {
        color: black;
        font-family: montserrat-regular;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
    }

    .nav_link__active {
        color: rgba(65, 65, 65, 0.623);
        font-family: montserrat-regular;
        font-size: 16px;
        text-decoration: none;

    }

    .nav_link:hover {
        color: rgb(1, 103, 255);
    }

    .nav_link_ico {
        font-size: 20px;
        margin-left: 35px;
    }
    .nav_login-link {
        color: rgb(0, 243, 61);
        font-family: montserrat-regular;
        font-weight: bold;
        text-decoration: none;
        font-size: 18px;
        cursor: pointer;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
    }

    .nav_logout-btn {
        color: rgb(250, 20, 20);
        font-family: montserrat-regular;
        font-weight: bold;
        font-size: 18px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        border: none;
        gap: 10px;
        align-items: center;
        background: transparent;
    }
}


@media (width < 800px) {
    .nav_logo-container {
        text-decoration: none;
        color: #0E73B7;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .nav_header {
        display: flex;
        position: fixed;
        justify-content: center;
        gap: 60vw;
        width: 100vw;
        align-items: center;
        padding: 25px 0 5px 0;
        transition: ease-in-out .3s;
    }

    .nav_header__scroll {
        background-color: rgb(255, 255, 255);
        box-shadow: 0 0 10px 1px rgba(95, 95, 95, 0.596);
        padding: 0px 0 0px 0 !important;
        height: 55px;
        transition: ease-in-out .4s;

    }

    .nav_header__navOpen {
        background-color: rgb(255, 255, 255);
        /* box-shadow: 0 0 10px 1px rgba(95, 95, 95, 0.596); */
        padding: 0px 0 0px 0 !important;
        height: 55px;
        transition: ease-in-out .4s;
        z-index: 5;

    }

    .nav_logo-container p {
        font-size: 30px;
        font-weight: 400;
        font-family: sans-serif;
    }

    .nav_logo {
        width: 43px;
        position: relative;
        margin-right: -10px;
        margin-left: -10px;

    }

    .nav_mobile-ico_btn {
        background-color: transparent;
        color: rgb(0, 0, 0);
        border: none;
        z-index: 99999999999999;
        font-size: 22px;
    }

    .nav_userimg-link {
        width: 22px;
        border-radius: 50%;
        z-index: 99999999999999;

    }

    .navigation-container {
        position: absolute;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }


    .nav_name {
        font-family: montserrat-regular;
        font-size: 30px;
        position: relative;
        top: 2px;
        color: #2A7CB4;
    }

    .nav_logo {
        width: 45px;
    }

    .nav_links-group__inActive {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: -280px;
        width: calc(100vw - 50px);
        transition: ease-in .3s;
        background-color: white;
        padding: 25px;

    }

    .nav_links-group__active {
        display: flex;
        flex-direction: column;
        width: calc(100vw - 50px);
        position: fixed;
        top: 55px;
        background-color: white;
        transition: ease-in .4s;
        height: fit-content;
        border-radius: 0 0 25px 25px;
        z-index: 99999999999999;
    }



    .nav_link {
        color: rgb(0, 0, 0);
        font-family: montserrat-regular;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        padding: 4px;

    }

    .nav_link__active {
        color: rgba(58, 58, 58, 0.479);
        text-decoration: none;
        font-family: montserrat-regular;
        font-size: 16px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        padding: 4px;


    }

    .nav_login-link {
        color: rgb(0, 243, 61);
        font-family: montserrat-regular;
        font-weight: bold;
        text-decoration: none;
        font-size: 18px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        padding: 5px;
        margin-top: 30px;
    }

    .nav_logout-btn {
        color: rgb(250, 20, 20);
        font-family: montserrat-regular;
        font-weight: bold;
        font-size: 18px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        padding: 5px;
        margin-top: 30px;
        border: none;
        background: transparent;
    }

    /*
    .nav_link:hover,
    .nav_link__active:hover {
        opacity: .7;
        color: rgb(17, 17, 16);
    }

    .nav_link_ico {
        font-size: 20px;
        margin-left: 35px;
    } */
}