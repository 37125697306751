.edit_profile {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    height: 100vh;
}

.edit-profile_header {
    display: flex;
    justify-content: space-between;
    padding: 20px 10px;
    align-items: center;

}

.edit-profile_head {
    font-weight: bold;
    font-family: montserrat-bold;
    font-size: 16px;
}

.edit-profile_btn {
    background-color: transparent;
    border: none;
    color: rgb(0, 0, 0);

}

.edit-profile_close-ico {
    font-size: 35px;
    position: relative;
    top: 6px;
    z-index: 3;

}

.edit-profile_save-btn {
    font-size: 16px;
    opacity: .8;
}

/* loading btn animation and filter */

.edit-profile_loading-icon {
    font-size: 18px;
    animation: spin 1s linear infinite;
    padding: 9px;
    opacity: .8;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.edit-profile_loading-screen-filter{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.514);
    z-index: 2;
    transition: ease-in-out .5s;
}
/* end */
.edit-profile_error-text{
    background-color: rgb(238, 40, 40);
    color: white;
    font-weight: bold;
    position: absolute;
    bottom: 5vh;
    padding: 10px 0;
    text-align: center;
    margin-left: 15vw;
    margin-right: 15vw;
    width: 70%;
}
.edit-profile_info {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
    margin-left: 15px;
    margin-right: 15px;

}

.edit-profile_info_img-div {
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
    position: relative;
}

.edit-profile_info-btn {
    background-color: transparent;
    font-family: montserrat-bold;
    border: none;
    font-weight: bold;
    color: rgb(0, 0, 0);
    font-size: 10px;

}

.edit-profile_user-photo {
    width: 175px;
    height: 175px;
    border-radius: 50%;
}
.edit-profile_noxprofile-ico{
    font-size: 175px;
}

.edit-profile_username {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: .5px solid rgba(161, 161, 161, 0.479);
    color: rgb(0, 0, 0);
    font-family: montserrat-bold;
    font-weight: bold;
    text-align: center;
    font-size: 30px;
}
.edit-profile_username:focus {
    outline: none;
    text-decoration: none;

}

.edit-profile_footer {
    font-size: 10px;
    text-align: center;
    margin-top: 35px;
    margin-left: 20%;
    opacity: .8;
    margin-right: 20%;

}

.edit-image-input {
    width: 180px;
    min-height: 230px;
    color: rgb(255, 255, 255);
    border-radius: 45%;
    position: absolute;
    top: -10px;
    opacity: .0;
    cursor: pointer;

}

.photo-cropper_crop-btn {
    position: absolute;
    background-color: rgba(8, 8, 8, 0.5);
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-size: 30px;
    padding: 10px;
    border: none;
    bottom: 30px;
    right: 30px;
    border-radius: 25px;
}