@font-face {
    font-family: montserrat-black;
    src: url('./MontserratAlternates-Black.otf');
}

@font-face {
    font-family: montserrat-light;
    src: url('./MontserratAlternates-Light.otf');
}
@font-face {
    font-family: montserrat-regular;
    src: url('./Montserrat-Regular.otf');
}
@font-face {
    font-family: montserrat-bold;
    src: url('./Montserrat-Bold.otf');
}
@font-face {
    font-family: montserrat-ExtraBold;
    src: url('./Montserrat-ExtraBold.otf');
}