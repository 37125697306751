*{
    margin: 0;
    padding: 0;
    font-family: montserrat-regular;
}
.loading_div{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(255, 255, 255, 0.452);
    z-index: 9999999999;
}
